import AirDatepicker from 'air-datepicker';
// @ts-expect-error No declaration file
import SuiVue from 'semantic-ui-vue';
import Vue from 'vue';
import VueBreadcrumbs from 'vue-2-breadcrumbs';

import App from './app.vue';
import router from './router';
import store from './store';
import 'semantic-ui-less/semantic.less';
import '@/assets/stylus/colors.styl';
import 'air-datepicker/air-datepicker.css';

Vue.config.productionTip = false;

Vue.use(VueBreadcrumbs, {
  template:
    '     <div v-if="$breadcrumbs.length" class="ui basic secondary segment">'
    + '     <div class="ui breadcrumb" style="display: flex">'
    + '       <router-link to="/" class="link section">Admin</router-link>'
    + '       <div v-for="(crumb, key, i) in $breadcrumbs" v-if="crumb.meta.breadcrumb" :key="key">'
    + '         <div class="section">&nbsp;/&nbsp;</div>'
    + '         <router-link :to="{ path: getPath(crumb) }" class="link section">{{ getBreadcrumb(crumb.meta.breadcrumb) }}</router-link>'
    + '       </div>'
    + '     </div>'
    + '   </div>',
});

Vue.directive('date', {
  bind(element: HTMLElement) {
    const input = element as HTMLInputElement;
    // eslint-disable-next-line no-new
    new AirDatepicker(element, {
      onSelect(event) {
        setTimeout(() => {
          input.value = event.formattedDate as string;
          input.dispatchEvent(new CustomEvent('input'));
        }, 100);
      },
    });
  },
});

Vue.directive('date-range', {
  bind(element: HTMLElement) {
    const input = element as HTMLInputElement;
    // eslint-disable-next-line no-new
    new AirDatepicker(element, {
      range: true,
      multipleDatesSeparator: ' - ',
      onSelect(event) {
        console.log(event);
        setTimeout(() => {
          input.value = event.formattedDate as string;
          input.dispatchEvent(new CustomEvent('input'));
        }, 100);
      },
    });
  },
});

Vue.use(SuiVue);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

store.dispatch('initialize').catch(console.error);
