
























































































































import { DateTime } from 'luxon';
import Vue from 'vue';

import BLogsSeller from '@/components/pages/logs/logs-seller.vue';
import BToolbar from '@/components/toolbar.vue';
import { Log } from '@/types/api/logs/log';
import { DropdownOption } from '@/types/types';
import eventBus from '@/utils/event-bus';
import { HttpClient } from '@/utils/http-client';

export default Vue.extend({
  name: 'settings-index',
  components: { BLogsSeller, BToolbar },
  data() {
    return {
      logs: [] as Log[],
      pages: 1,
      filter: '',
      searchLoading: false,
    };
  },
  watch: {
    async $route() {
      await this.fetchLogs();
    },
    async filter() {
      const sp = new URLSearchParams(this.$route.query as Record<string, string>);
      if (this.filter === 'all') {
        sp.delete('filter');
      } else {
        sp.set('filter', this.filter);
      }

      await this.$router.push(`?${sp.toString()}`).catch(() => 1);
    },
  },
  async created(): Promise<void> {
    this.filter = this.$route.query.filter ? this.$route.query.filter as string : 'all';

    await this.fetchLogs();
    eventBus.$on('server:synced', async () => {
      await this.fetchLogs();
    });
  },
  beforeDestroy(): void {
    eventBus.$off('server:synced');
  },
  computed: {
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    date() {
      return (log: Log) => DateTime.fromISO(log.createdAt).toFormat('dd.LL.yyyy HH:mm:ss');
    },
    filtersDropdownOptions(): DropdownOption[] {
      return [{
        key: 'all',
        value: 'all',
        text: 'Все',
      }, {
        key: 'price',
        value: 'price',
        text: 'Цены',
      }, {
        key: 'stock',
        value: 'stock',
        text: 'Остатки',
      }];
    },
  },
  methods: {
    async fetchLogs(): Promise<void> {
      this.searchLoading = true;
      const count = 10;
      const page = this.$route.query.page ? +this.$route.query.page : 1;
      const search = this.$route.query.search ? this.$route.query.search as string : '';
      const filter = this.$route.query.filter ? this.$route.query.filter as string : 'all';
      const { items, total } = await HttpClient.getLogs({
        searchParams: {
          page,
          count,
          search,
          filter,
        },
      });
      this.logs = items;
      this.pages = Math.ceil(total / count);
      this.searchLoading = false;
    },
  },
});
