





























































































import Vue from 'vue';

import { Cabinet } from '@/types/api/cabinets';
import { PriceCompareCreateBody } from '@/types/api/price_compares/store';
import { Product } from '@/types/api/products/product';
import { DropdownOption } from '@/types/types';
import { HttpClient } from '@/utils/http-client';
import {
  getServerErrorMessage,
} from '@/utils/utils';

export default Vue.extend({
  name: 'price-compares-create',
  data() {
    return {
      priceCompare: {
        article: '',
        cabinetId: 0,
        enabled: true,
        adjustment: false,
        adjustmentValue: 0,
        adjustmentMinPrice: 0,
        adjustmentMaxPrice: 0,
      } as PriceCompareCreateBody,
      cabinets: [] as Cabinet[],
      products: [] as Product[],
      errors: [] as string[],
      searchLoading: false,
      saving: false,
    };
  },
  async created(): Promise<void> {
    this.cabinets = await HttpClient.getCabinets();
    this.priceCompare.cabinetId = this.cabinets[0]?.id || 0;
    await this.fetchProducts();
  },
  computed: {
    cabinetsDropdownOptions(): DropdownOption[] {
      return this.cabinets.map(
        (cabinet) => ({
          key: cabinet.id,
          value: cabinet.id,
          text: cabinet.name,
        }),
      );
    },

    productsDropdownOptions(): DropdownOption[] {
      return this.products.map(
        (product) => ({
          key: product.article,
          value: product.article,
          text: product.article,
        }),
      );
    },
  },
  methods: {
    async save(): Promise<void> {
      this.errors = [];

      this.saving = true;
      const success = await this.storePriceCompare();
      if (success) {
        await this.$router.push({ name: 'price_compares.index' });
      }

      this.saving = false;
    },

    async fetchProducts(event?: KeyboardEvent) {
      const target = event?.target as HTMLInputElement | undefined;

      this.searchLoading = true;
      const { items } = await HttpClient.getProducts({
        searchParams: {
          count: 30,
          search: target?.value || '',
        },
      });
      this.products = items;
      this.searchLoading = false;
    },

    async storePriceCompare(): Promise<boolean> {
      try {
        await HttpClient.storePriceCompare(this.priceCompare);
      } catch (error) {
        this.errors = await getServerErrorMessage(error);
        return false;
      }

      return true;
    },
  },
});
