



















import Vue from 'vue';

export default Vue.extend({
  name: 'b-logs-seller',
  props: {
    value: {
      required: true,
      type: Object,
    },
  },
});
