

























































































































import Vue from 'vue';

import BSortingLink from '@/components/sorting-link.vue';
import BToolbar from '@/components/toolbar.vue';
import { Cabinet } from '@/types/api/cabinets';
import { PriceCompare } from '@/types/api/price_compares';
import { DropdownOption } from '@/types/types';
import eventBus from '@/utils/event-bus';
import { HttpClient } from '@/utils/http-client';

export default Vue.extend({
  name: 'price-compares-index',
  components: { BSortingLink, BToolbar },
  data() {
    return {
      priceCompares: [] as PriceCompare[],
      cabinets: [] as Cabinet[],
      pages: 1,
      filter: '',
      searchLoading: false,
      deleteDialogOpened: false as boolean,
      deletePriceCompareId: null as number | null,
      deleting: false as boolean,
      syncing: [] as number[],
      makingExport: false as boolean,
    };
  },
  watch: {
    async $route() {
      await this.fetchPriceCompares();
    },
    async filter() {
      const sp = new URLSearchParams(this.$route.query as Record<string, string>);
      if (this.filter === 'all') {
        sp.delete('filter');
      } else {
        sp.set('filter', this.filter);
      }

      await this.$router.push(`?${sp.toString()}`);
    },
  },
  async created(): Promise<void> {
    this.cabinets = await HttpClient.getCabinets();
    this.filter = this.$route.query.filter ? this.$route.query.filter as string : 'all';

    await this.fetchPriceCompares();
    eventBus.$on('server:synced', async () => {
      await this.fetchPriceCompares();
    });
  },
  beforeDestroy(): void {
    eventBus.$off('server:synced');
  },
  computed: {
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    price() {
      return (price?: number): string => (price ? `${price} ₽` : '-');
    },
    filtersDropdownOptions(): DropdownOption[] {
      return [{
        key: 'all',
        value: 'all',
        text: 'Все',
      }, {
        key: 'cheaper',
        value: 'cheaper',
        text: 'Есть дешевле',
      }];
    },
  },
  methods: {
    async fetchPriceCompares() {
      this.searchLoading = true;
      const count = 10;
      const page = this.$route.query.page ? +this.$route.query.page : 1;
      const search = this.$route.query.search ? this.$route.query.search as string : '';
      const orderColumn = this.$route.query.column ? this.$route.query.column as string : 'article';
      const orderDirection = this.$route.query.direction ? this.$route.query.direction as string : 'asc';
      const filter = this.$route.query.filter ? this.$route.query.filter as string : 'all';
      const { items, total } = await HttpClient.getPriceCompares({
        searchParams: {
          page,
          count,
          search,
          orderColumn,
          orderDirection,
          filter,
        },
      });
      this.priceCompares = items;
      this.pages = Math.ceil(total / count);
      this.searchLoading = false;
    },

    openDeleteDialog(id: number): void {
      this.deletePriceCompareId = id;
      this.deleteDialogOpened = true;
    },

    closeDeleteDialog(): void {
      this.deletePriceCompareId = null;
      this.deleteDialogOpened = false;
      this.deleting = false;
    },

    async deleteEpisode(): Promise<void> {
      if (!this.deletePriceCompareId) return;
      this.deleting = true;

      await HttpClient.deletePriceCompareById(this.deletePriceCompareId);
      await this.fetchPriceCompares();
      this.closeDeleteDialog();
    },

    async syncPriceCompare(id: number): Promise<void> {
      this.syncing.push(id);

      await HttpClient.syncPriceCompare(id);
      eventBus.$emit('server:synced');

      const index = this.syncing.indexOf(id);
      if (index !== -1) {
        this.syncing.splice(index, 1);
      }
    },

    async downloadExport(): Promise<void> {
      this.makingExport = true;

      try {
        const link = await HttpClient.makeParserExport();

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = link;
        a.download = '';
        document.body.append(a);
        a.click();
      } finally {
        this.makingExport = false;
      }
    },
  },
});
