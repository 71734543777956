































































































































































































import Vue from 'vue';

import BProductsParser from '@/components/pages/products/products-parser.vue';
import BProductsSets from '@/components/pages/products/products-sets.vue';
import { Cabinet } from '@/types/api/cabinets';
import {
  Product,
  ProductVariant,
} from '@/types/api/products/product';
import { DropdownOption } from '@/types/types';
import eventBus from '@/utils/event-bus';
import { HttpClient } from '@/utils/http-client';
import {
  getServerErrorMessage,
} from '@/utils/utils';

export default Vue.extend({
  name: 'products-edit',
  components: { BProductsSets, BProductsParser },
  data() {
    return {
      product: {
        id: -1,
        article: '',
        supplierArticle: null,
        title: '',
        brand: '',
        variants: [],
        importFullStock: false,
        balanceStocks: false,
        balanceStocksCabinetIds: [],
        stocksSyncProducts: [],
        barcode: null,
        minPriceByn: null,
        minPriceRub: null,
        minimumRetailPrice: null,
        comment: null,
        parser: {
          ozonQuery: '',
          ozonWhitelist: '',
          ozonBlacklist: '',
          wbWhitelist: '',
          wbBlacklist: '',
          wbQuery: '',
          variants: [],
          links: [],
        },
      } as Product,
      cabinets: [] as Cabinet[],
      errors: [] as string[],
      applying: false,
      saving: false,
      sharedStock: 0,
      activeTab: 0,
    };
  },
  async created(): Promise<void> {
    this.cabinets = await HttpClient.getCabinets();
    await this.fetchProduct();
    eventBus.$on('server:synced', async () => {
      await this.fetchProduct();
    });
  },
  beforeDestroy(): void {
    eventBus.$off('server:synced');
  },
  watch: {
    errors: {
      deep: true,
      handler(value: string[]) {
        if (value.length > 0) {
          this.activeTab = 0;
        }
      },
    },
    'product.variants': {
      deep: true,
      handler(value: ProductVariant[]) {
        const stocks: number[] = [];
        value.forEach((variant) => {
          if (variant.stock !== null && this.product.balanceStocksCabinetIds.includes(variant.cabinetId)) {
            stocks.push(variant.stock);
          }
        });

        this.sharedStock = stocks.length > 0 ? Math.min(...stocks) : 0;
      },
    },
  },
  computed: {
    cabinetName() {
      return (cabinetId: number): string => {
        const cabinet = this.cabinets.find((c) => c.id === cabinetId);
        return cabinet?.name !== undefined ? cabinet.name.toString() : '-';
      };
    },
    cabinetsDropdownOptions(): DropdownOption[] {
      return this.cabinets.map(
        (cabinet) => ({
          key: cabinet.id,
          value: cabinet.id,
          text: cabinet.name,
        }),
      );
    },
  },
  methods: {
    async save(redirect = false): Promise<void> {
      this.errors = [];

      if (redirect) {
        this.saving = true;
        const success = await this.patchProduct();
        if (success) {
          await this.$router.push({ name: 'products.index' });
        }
      } else {
        this.applying = true;
        await this.patchProduct();
      }

      this.saving = false;
      this.applying = false;
    },

    async fetchProduct(): Promise<void> {
      const product: Product = (await HttpClient.getProductByArticle(
        { article: this.$route.params.article },
      ));
      if (!product) return;

      this.product = product;
    },

    updateStocks() {
      if (this.product.balanceStocks) {
        this.product.variants.forEach((variant, i) => {
          if (
            variant.stock !== null
            && variant.stock !== this.sharedStock
            && this.product.balanceStocksCabinetIds.includes(variant.cabinetId)
          ) {
            this.product.variants[i].stock = this.sharedStock;
          }
        });
      }
    },

    async patchProduct():Promise<boolean> {
      try {
        this.updateStocks();
        const body = JSON.parse(JSON.stringify(this.product));
        body.minPriceRub = !body.minPriceRub ? null : +body.minPriceRub;
        body.minimumRetailPrice = !body.minimumRetailPrice ? null : +body.minimumRetailPrice;
        body.comment = !body.comment?.trim() ? null : body.comment;

        await HttpClient.patchProduct(this.product.article, body);
      } catch (error) {
        this.errors = await getServerErrorMessage(error);
        return false;
      }

      return true;
    },
  },
});
