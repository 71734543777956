// eslint-disable-next-line no-shadow
export enum CabinetType {
  OZON = 'OZON',
  WB = 'WB',
}

export interface Cabinet {
  id: number;
  enabled: boolean;
  type: CabinetType;
  name: string;
  vat: number;
  credentials: Record<string, string>;
}
