






import Vue from 'vue';

export default Vue.extend({
  name: 'b-delivery-status',
  props: {
    status: {
      required: true,
      type: String,
    },
  },
  computed: {
    title(): string {
      switch (this.status) {
        case 'new':
          return 'Новый';
        case 'awaiting':
          return 'Ожидает';
        case 'delivering':
          return 'Отправлен';
        case 'delivered':
          return 'Доставлен';
        case 'cancelled':
          return 'Отменён';
        case 'refund':
          return 'Возврат';
        default:
          return 'Неизвестно';
      }
    },
    modifiers(): string[] {
      const modifiers: string[] = [];
      modifiers.push(`delivery-status_type_${this.status}`);
      return modifiers;
    },
  },
});
