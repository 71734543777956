


























































import { DateTime } from 'luxon';
import Vue from 'vue';

import BToolbar from '@/components/toolbar.vue';
import { Task } from '@/types/api/tasks';
import eventBus from '@/utils/event-bus';
import { HttpClient } from '@/utils/http-client';

export default Vue.extend({
  name: 'tasks-index',
  components: { BToolbar },
  data() {
    return {
      tasks: [] as Task[],
      pages: 1,
      filter: '',
      searchLoading: false,
    };
  },
  watch: {
    async $route() {
      await this.fetchTasks();
    },
    async filter() {
      const sp = new URLSearchParams(this.$route.query as Record<string, string>);
      if (this.filter === 'all') {
        sp.delete('filter');
      } else {
        sp.set('filter', this.filter);
      }

      await this.$router.push(`?${sp.toString()}`).catch(() => 1);
    },
  },
  async created(): Promise<void> {
    this.filter = this.$route.query.filter ? this.$route.query.filter as string : 'all';

    await this.fetchTasks();
    eventBus.$on('server:synced', async () => {
      await this.fetchTasks();
    });
  },
  beforeDestroy(): void {
    eventBus.$off('server:synced');
  },
  computed: {
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    date() {
      return (task: Task) => DateTime.fromISO(task.createdAt).toFormat('dd.LL.yyyy HH:mm:ss');
    },
  },
  methods: {
    async fetchTasks(): Promise<void> {
      this.searchLoading = true;
      const count = 10;
      const page = this.$route.query.page ? +this.$route.query.page : 1;
      const { items, total } = await HttpClient.getTasks({
        searchParams: {
          page,
          count,
        },
      });
      this.tasks = items;
      this.pages = Math.ceil(total / count);
      this.searchLoading = false;
    },
  },
});
