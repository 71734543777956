



























































































import { DateTime } from 'luxon';
import Vue from 'vue';

import BOrdersTableHeader from '../../components/pages/orders/orders-table-header.vue';
import BPagination from '../../components/pagination.vue';
import BNameCell from '../../components/products-table/name-cell.vue';
import BTableCell from '../../components/table/table-cell.vue';
import { Cabinet } from '../../types/api/cabinets';
import { Order } from '../../types/api/orders';

import { HttpClient } from '@/utils/http-client';
import { downloadByLink } from '@/utils/utils';

export default Vue.extend({
  name: 'orders-report',
  components: {
    BPagination,
    BOrdersTableHeader,
    BNameCell,
    BTableCell,
  },
  data() {
    return {
      orders: [] as Order[],
      pages: 1,
      perPage: 10,
      total: 0,
      totalCost: 0,
      searchLoading: false,
      withDate: true,

      checkAll: false,

      makingExport: false,
      makingList: false,
    };
  },
  watch: {
    async $route() {
      await this.fetchOrders();
    },
    async withDate() {
      await this.$router.replace({
        path: this.$router.currentRoute.fullPath,
        query: {
          page: '1',
        },
      }).catch(() => 1);
      await this.fetchOrders();
    },
  },
  async created(): Promise<void> {
    await this.fetchOrders();
  },
  computed: {
    cabinets(): Cabinet[] {
      return this.$store.state.cabinets;
    },
    cabinet() {
      return (cabinetId: number) => {
        if (!cabinetId) return 'N/A';
        return this.cabinets.find((o) => o.id === cabinetId) ?? {
          name: 'N/A',
          type: '',
        };
      };
    },
    date() {
      return (date: string | null) => {
        if (!date) return null;
        return DateTime.fromISO(date).toFormat('dd.LL.yyyy');
      };
    },
  },
  methods: {
    async fetchOrders(): Promise<void> {
      this.searchLoading = true;
      let cabinets: string[] = [];
      let deliveryType: string | null = null;

      const page = this.$route.query.page ? +this.$route.query.page : 1;
      const routeCabinets = this.$route.query.cabinets;
      const routeDeliveryType = this.$route.query.delivery_type;
      const date = this.$route.params.date as string;

      if (routeCabinets) {
        cabinets = typeof routeCabinets === 'string' ? [routeCabinets] : routeCabinets as string[];
      }
      if (routeDeliveryType) {
        deliveryType = ['fbs', 'fbo'].includes(routeDeliveryType.toString()) ? routeDeliveryType.toString() : null;
      }

      const sp = new URLSearchParams();
      sp.set('date', date);
      sp.set('page', page.toString());
      sp.set('count', this.perPage.toString());
      sp.set('withDate', `${this.withDate}`);

      cabinets.forEach((id) => {
        sp.append('cabinet[]', id);
      });

      if (deliveryType) {
        sp.set('deliveryType', deliveryType);
      }

      const { items, total, totalCost } = await HttpClient.getOrdersForReport({
        searchParams: sp,
      });
      this.orders = items;
      this.pages = Math.ceil(total / this.perPage);
      this.total = total;
      this.totalCost = totalCost;
      this.searchLoading = false;

      if (items.length === 0 && page > 1) {
        await this.$router.replace({
          path: this.$route.fullPath,
          query: { page: (page - 1).toString() },
        });
      }
    },
    async downloadExport(): Promise<void> {
      this.makingExport = true;

      try {
        const response = await HttpClient.makeOrdersReportsExport(this.$route.params.date);
        downloadByLink(response.path);

        if (response.hasEmptyBarcodes) {
          // eslint-disable-next-line no-alert
          alert('В выгрузке присутствуют заказы без Штрихкода');
        }
      } finally {
        this.makingExport = false;
      }
    },
    async downloadList(): Promise<void> {
      this.makingList = true;

      try {
        const link = await HttpClient.makeOrdersReportsList(this.$route.params.date);
        downloadByLink(link);
      } finally {
        this.makingList = false;
      }
    },
    async setShipmentAt(order: Order) {
      const date = order.shipmentAt ? null : this.$route.params.date as string;
      // eslint-disable-next-line no-param-reassign
      order.shipmentAt = null;

      const updatedOrder = await HttpClient.setOrderShipmentAt(order.id, date);

      // eslint-disable-next-line no-param-reassign
      order.shipmentAt = updatedOrder.shipmentAt;

      await this.fetchOrders();
    },

    async setShipmentAtForAll() {
      const date = this.$route.params.date as string;

      await Promise.all(
        this.orders.map((order) => {
          // eslint-disable-next-line no-param-reassign
          order.shipmentAt = date;
          return HttpClient.setOrderShipmentAt(order.id, date);
        }),
      );

      await this.fetchOrders();
      this.checkAll = false;
    },
  },
});
