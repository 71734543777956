




































































import { DateTime } from 'luxon';
import Vue from 'vue';

import BOrdersTableHeader from '@/components/pages/orders/orders-table-header.vue';
import BNameCell from '@/components/products-table/name-cell.vue';
import BTableCell from '@/components/table/table-cell.vue';
import BToolbar from '@/components/toolbar.vue';
import type { GroupedOrder } from '@/types/api/orders/grouped';
import { HttpClient } from '@/utils/http-client';

export default Vue.extend({
  name: 'orders-index',
  components: {
    BToolbar,
    BOrdersTableHeader,
    BNameCell,
    BTableCell,
  },
  data() {
    return {
      activeTab: 1,
      orders: [] as GroupedOrder[],
      pages: 1,
      perPage: 10,
      total: 0,
      searchLoading: false,
    };
  },
  watch: {
    async $route() {
      await this.fetchOrders();
    },
    async activeTab() {
      if (this.activeTab === 0) {
        await this.$router.push({ name: 'orders.index' });
      } else if (this.activeTab === 2) {
        await this.$router.push({ name: 'orders.calendar' });
      }
    },
  },
  async created(): Promise<void> {
    await this.fetchOrders();
  },
  methods: {
    async fetchOrders(): Promise<void> {
      this.searchLoading = true;
      const page = this.$route.query.page ? +this.$route.query.page : 1;

      let cabinets: string[] = [];
      let statuses: string[] = [];
      let deliveryType: string | null = null;

      const routeCabinets = this.$route.query.cabinets;
      const routeStatuses = this.$route.query.statuses;
      const routeDeliveryType = this.$route.query.delivery_type;
      const routeSince = this.$route.query.since as string | undefined;
      const routeTo = this.$route.query.to as string | undefined;
      const search = this.$route.query.search as string | undefined;

      if (routeCabinets) {
        cabinets = typeof routeCabinets === 'string' ? [routeCabinets] : routeCabinets as string[];
      }
      if (routeStatuses) {
        statuses = typeof routeStatuses === 'string' ? [routeStatuses] : routeStatuses as string[];
      }
      if (routeDeliveryType) {
        deliveryType = ['fbs', 'fbo'].includes(routeDeliveryType.toString()) ? routeDeliveryType.toString() : null;
      }

      const sp = new URLSearchParams();
      sp.set('page', page.toString());
      sp.set('count', this.perPage.toString());

      cabinets.forEach((id) => {
        sp.append('cabinet[]', id);
      });

      statuses.forEach((id) => {
        sp.append('status[]', id);
      });

      if (deliveryType) {
        sp.set('deliveryType', deliveryType);
      }

      if (routeSince) {
        sp.set('since', DateTime.fromISO(routeSince).toLocal().toISO());
      }

      if (routeTo) {
        sp.set('to', DateTime.fromISO(routeTo).toLocal().plus({ days: 1 }).toISO());
      }

      if (search) {
        sp.set('search', search);
      }

      const { items, total } = await HttpClient.getGroupedOrders({
        searchParams: sp,
      });
      this.orders = items;
      this.pages = Math.ceil(total / this.perPage);
      this.total = total;
      this.searchLoading = false;
    },
    formatSum(sum: number): string {
      return new Intl.NumberFormat('ru-RU', {
        style: 'currency', currency: 'RUB',
      }).format(sum);
    },
  },
});
