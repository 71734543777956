






















import Vue, { PropType } from 'vue';

import BIcon from '@/components/icon.vue';

export interface SelectOption {
  id: number | string | null;
  title: string;
}

export default Vue.extend({
  name: 'b-select',
  components: { BIcon },
  props: {
    options: {
      required: false,
      type: Array as PropType<SelectOption[]>,
      default: () => [],
    },
    value: {
      required: false,
      type: [Number, String] as PropType<number | string | null>,
      default: null,
    },
    placeholder: {
      required: false,
      type: String as PropType<string>,
      default: 'Нет',
    },
    nullText: {
      required: false,
      type: String as PropType<string>,
      default: 'Все',
    },
  },
  data() {
    return {
      focused: false as boolean,
    };
  },
  mounted() {
    document.addEventListener('click', this.onDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  },
  computed: {
    modifiers(): string[] {
      const modifiers: string[] = [];
      if (this.activeDefault) { modifiers.push('select_style_placeholder'); }
      if (this.focused) { modifiers.push('select_state_focus'); }
      return modifiers;
    },
    itemModifiers() {
      return (item: SelectOption): string[] => {
        const modifiers: string[] = [];
        if (this.activeOption !== null && item.id === this.activeOption.id) {
          modifiers.push('select__item_state_active');
        }
        return modifiers;
      };
    },
    index(): number | null {
      const index = this.options?.findIndex((item) => item.id === this.value);
      return index !== -1 ? index : null;
    },
    activeOption(): SelectOption | null {
      if (this.index === null) return null;
      return this.options?.[this.index] || null;
    },
    activeDefault(): boolean {
      return this.activeOption?.id === null;
    },
    title(): string | null {
      if (this.activeDefault || this.activeOption === null) return this.placeholder;
      return this.activeOption.title;
    },
  },
  methods: {
    focus(): void {
      this.focused = true;
    },
    blur(): void {
      this.focused = false;
    },
    onDocumentClick(event: MouseEvent): void {
      const parent = this.$refs.parent as HTMLDivElement;
      if (!parent?.contains(event.target as Node) && this.focused) {
        this.blur();
      }
    },
    onMousedown(): void {
      if (!this.focused) {
        this.focus();
      } else {
        this.blur();
      }
    },
    onSelect(id: number | string | null): void {
      this.$emit('input', id);
      this.blur();
    },
  },
});
