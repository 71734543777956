































































































import Vue from 'vue';

import { Task } from '@/types/api/tasks';
import { HttpClient } from '@/utils/http-client';

export default Vue.extend({
  name: 'tasks-show',
  data() {
    return {
      modal: false as boolean,
      childIndex: null as number | null,
      task: {
        id: 0,
        status: 'WAIT',
        title: '',
        error: '',
        progress: 0,
        parentId: null,
        createdAt: '1970-01-01T00:00:00.000Z',
        finishedAt: null,
        children: [],
      } as Task,

      error: null as null | Record<string, string>,
    };
  },
  async created(): Promise<void> {
    this.task = await HttpClient.getTaskById(this.$route.params.id);
    this.task.children.reverse();

    const intervalId = window.setInterval(async () => {
      if (this.task.status === 'SUCCESS' || !this.$route.params.id) {
        window.clearInterval(intervalId);
        return;
      }

      this.task = await HttpClient.getTaskById(this.$route.params.id);
      this.task.children.reverse();
    }, 1000);
  },
  computed: {
    progressColor(): string {
      if (this.task.status === 'FAIL' || this.task.status === 'CANCEL') {
        return 'red';
      }

      if (this.task.status === 'PROGRESS') {
        return 'yellow';
      }

      return 'green';
    },
  },
  methods: {
    createError(text: string): { title: string; text: string; } {
      const array = text.split('\n');
      const title = array[0];

      array.splice(0, 1);

      return { title, text: array.join('<br />') };
    },

    openError(error?: string): void {
      if (error) {
        this.error = this.createError(error);
        this.modal = true;
      }
    },

    closeModal(): void {
      this.error = null;
      this.modal = false;
    },
  },
});
