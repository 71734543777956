














// eslint-disable-next-line import/no-duplicates
import '@fullcalendar/core';
// eslint-disable-next-line import/no-duplicates
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/vue';
import Vue from 'vue';

import { HttpClient } from '@/utils/http-client';

export default Vue.extend({
  name: 'orders-index',
  components: {
    FullCalendar,
  },
  data() {
    return {
      activeTab: 2,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth',
        },
        editable: true,
        selectable: true,
        dayMaxEvents: true,
        locale: 'ru',
        firstDay: 1,
        defaultTimedEventDuration: '00:30:00',
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
        },
        showNonCurrentDates: false,
        dateClick: (info) => {
          this.$router.push({
            name: 'orders.report',
            params: { date: info.dateStr },
            query: { delivery_type: 'fbs' },
          });
        },
        datesSet: async (payload) => {
          // @ts-expect-error Bad type declarations
          await this.fetchStats(payload.start.getFullYear(), payload.start.getMonth() + 1);
        },
      } as CalendarOptions,
      totalOrders: 0,
      totalCost: 0,
    };
  },
  watch: {
    async activeTab() {
      if (this.activeTab === 0) {
        await this.$router.push({ name: 'orders.index' });
      } else if (this.activeTab === 1) {
        await this.$router.push({ name: 'orders.grouped' });
      }
    },
  },
  computed: {
    formattedCost(): string {
      return new Intl.NumberFormat('ru-RU', {
        style: 'currency', currency: 'RUB',
      }).format(this.totalCost);
    },
  },
  methods: {
    async fetchStats(year: number, month: number): Promise<void> {
      const response = await HttpClient.getOrdersMonthStats(year, month);
      this.totalOrders = response.totalOrders;
      this.totalCost = response.totalCost;
    },
  },
});
