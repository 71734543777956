































import Vue, { PropType } from 'vue';

import BIcon from '@/components/icon.vue';

export interface MultipleSelectOption {
  id: number | string;
  title: string;
}

export default Vue.extend({
  name: 'b-multiple-select',
  components: { BIcon },
  props: {
    options: {
      required: false,
      type: Array as PropType<MultipleSelectOption[]>,
      default: () => [],
    },
    value: {
      required: false,
      type: Array as PropType<Array<number | string>>,
      default: () => [],
    },
    placeholder: {
      required: false,
      type: String as PropType<string>,
      default: 'Нет',
    },
  },
  data() {
    return {
      focused: false as boolean,
    };
  },
  mounted() {
    document.addEventListener('click', this.onDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  },
  computed: {
    modifiers(): string[] {
      const modifiers: string[] = [];
      if (this.activeDefault) { modifiers.push('select_style_placeholder'); }
      if (this.focused) { modifiers.push('select_state_focus'); }
      return modifiers;
    },
    itemModifiers() {
      return (item: MultipleSelectOption): string[] => {
        const modifiers: string[] = [];
        if (this.activeOptions.some((option) => option.id === item.id)) {
          modifiers.push('select__item_state_active');
        }
        return modifiers;
      };
    },
    activeOptions(): MultipleSelectOption[] {
      return this.options?.filter((item) => this.value?.includes(item.id)) || [];
    },
    activeDefault(): boolean {
      return this.activeOptions.length === 0;
    },
  },
  methods: {
    focus(): void {
      this.focused = true;
    },
    blur(): void {
      this.focused = false;
    },
    onDocumentClick(event: MouseEvent): void {
      const parent = this.$refs.parent as HTMLDivElement;
      if (!parent?.contains(event.target as Node) && this.focused) {
        this.blur();
      }
    },
    onMousedown(): void {
      if (!this.focused) {
        this.focus();
      } else {
        this.blur();
      }
    },
    onSelect(id: number | string): void {
      const value = [...this.value];

      const index = value.indexOf(id);
      if (index !== -1) {
        value.splice(index, 1);
      } else {
        value.push(id);
      }

      this.$emit('input', value);
    },
  },
});
