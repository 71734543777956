





































































import Vue from 'vue';

import { Cabinet } from '@/types/api/cabinets';
import {
  Product,
  ProductVariant,
} from '@/types/api/products/product';
import { DropdownOption } from '@/types/types';
import { HttpClient } from '@/utils/http-client';
import { getServerErrorMessage } from '@/utils/utils';

export default Vue.extend({
  name: 'products-copy',
  data() {
    return {
      update: false as boolean,

      cabinets: [] as Cabinet[],
      sourceCabinetId: null as number | null,
      targetCabinetId: null as number | null,

      products: [] as Product[],
      selectedProduct: null as Product | null,
      productsSearchLoading: false,

      errors: [] as string[],
      applying: false,
    };
  },
  computed: {
    productsDropdownOptions(): DropdownOption[] {
      return this.products.map(
        (product) => ({
          key: product.id,
          value: product.id,
          text: product.article,
        }),
      );
    },
    sourceCabinetDropdownOptions(): DropdownOption[] {
      return this.cabinets
        .filter((cabinet) => this.selectedProduct?.variants.some(
          (variant) => variant.cabinetId === cabinet.id,
        ))
        .map(
          (cabinet) => ({
            key: cabinet.id,
            value: cabinet.id,
            text: cabinet.name,
          }),
        );
    },
    targetCabinetDropdownOptions(): DropdownOption[] {
      return this.cabinets
        .filter((cabinet) => this.sourceCabinet?.type === cabinet.type)
        .filter((cabinet) => this.sourceCabinetId !== cabinet.id)
        .filter((cabinet) => this.update || !this.selectedProduct?.variants.some(
          (variant) => variant.cabinetId === cabinet.id,
        ))
        .map(
          (cabinet) => ({
            key: cabinet.id,
            value: cabinet.id,
            text: cabinet.name,
          }),
        );
    },
    sourceCabinet(): Cabinet | null {
      return this.cabinets.find((cabinet) => cabinet.id === this.sourceCabinetId) ?? null;
    },
    selectedVariant(): ProductVariant | null {
      return this.selectedProduct?.variants.find((variant) => variant.cabinetId === this.sourceCabinetId) ?? null;
    },
  },
  async created(): Promise<void> {
    this.cabinets = await HttpClient.getCabinets();
  },
  methods: {
    async fetchConnectedProducts(event?: KeyboardEvent) {
      const target = event?.target as HTMLInputElement | undefined;

      this.productsSearchLoading = true;
      const { items } = await HttpClient.getProducts({
        searchParams: {
          count: 30,
          search: target?.value || '',
        },
      });
      this.products = items;
      this.productsSearchLoading = false;
    },
    selectProduct(id: number) {
      this.selectedProduct = this.products.find((p) => p.id === id) ?? null;
    },
    async copy(): Promise<void> {
      this.applying = true;

      try {
        if (this.selectedVariant?.id && this.targetCabinetId) {
          await HttpClient.copy({
            variantId: this.selectedVariant?.id,
            targetCabinetId: this.targetCabinetId,
            update: this.update,
          });
        }
      } catch (error) {
        this.errors = await getServerErrorMessage(error);
      } finally {
        this.applying = false;
      }
    },
  },
});
