












import Vue from 'vue';

export default Vue.extend({
  name: 'b-sorting-link',
  props: {
    column: {
      required: false,
      type: String,
    },
  },
  computed: {
    searchParams(): URLSearchParams {
      return new URLSearchParams(this.$route.query as Record<string, string>);
    },
    linkQuery(): string {
      const sp = new URLSearchParams(this.$route.query as Record<string, string>);
      if (this.direction === 'desc') {
        sp.delete('column');
        sp.delete('direction');
      } else if (this.direction === 'asc') {
        sp.set('column', this.column);
        sp.set('direction', 'desc');
      } else {
        sp.set('column', this.column);
        sp.set('direction', 'asc');
      }
      return `?${sp.toString()}`;
    },
    direction(): string | boolean {
      if (this.searchParams.get('column') === this.column) {
        return this.searchParams.get('direction')?.toLowerCase() ?? 'asc';
      }

      return false;
    },
  },
});
