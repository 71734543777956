
















import Vue from 'vue';

export default Vue.extend({
  name: 'b-name-cell',
  props: {
    article: {
      required: true,
      type: String,
    },
    supplierArticle: {
      required: false,
      type: String,
      default: null,
    },
    barcode: {
      required: false,
      type: String,
      default: null,
    },
  },
});
