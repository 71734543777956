












































import Vue from 'vue';

import BPagination from '@/components/pagination.vue';

export default Vue.extend({
  name: 'b-toolbar',
  components: { BPagination },
  data() {
    return {
      searchText: '',
      searchTimeoutId: null as number | null,
    };
  },
  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Создать',
    },
    pages: {
      type: Number,
      required: false,
    },
    search: {
      type: Boolean,
      required: false,
    },
    searchLoading: {
      type: Boolean,
      required: false,
    },
    hidePagination: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    onSearch(): void {
      if (this.searchTimeoutId !== null) {
        clearInterval(this.searchTimeoutId);
      }

      this.$emit('update:search-loading', true);
      this.searchTimeoutId = setTimeout(async () => {
        this.$emit('update:search-loading', false);

        const query = { ...this.$route.query };
        query.search = this.searchText;

        if (query.search === '') {
          delete query.search;
        }

        delete query.page;

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.replace({ path: this.$route.path, query }).catch(() => {});
        this.$emit('search');
      }, 1000);
    },
  },
  created() {
    this.searchText = (this.$route.query.search || '') as string;
  },
});
