






import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'b-icon',
  props: {
    name: {
      required: true,
      type: String as PropType<string>,
    },
  },
  computed: {
    iconPath(): string {
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,unicorn/prefer-module
      const icon = require('@/assets/icons.svg');
      return `${icon}#${this.name}`;
    },
    className(): string {
      return `icon icon_${this.name}`;
    },
  },
});
