

























import { DateTime } from 'luxon';
import Vue from 'vue';

import BDate from '@/components/date.vue';
import BOrdersCabinetsDropdown from '@/components/pages/orders/orders-cabinets-dropdown.vue';
import BOrdersDeliveryTypesDropdown from '@/components/pages/orders/orders-delivery-types-dropdown.vue';
import BOrdersStatusesDropdown from '@/components/pages/orders/orders-statuses-dropdown.vue';

export default Vue.extend({
  name: 'b-orders-table-header',
  components: {
    BOrdersDeliveryTypesDropdown,
    BDate,
    BOrdersStatusesDropdown,
    BOrdersCabinetsDropdown,
  },
  data() {
    return {
      created: false,
      dates: [] as DateTime[],
      selectedCabinets: [] as number[],
      selectedStatuses: [] as string[],
      selectedDeliveryType: null as string | null,
    };
  },
  props: {
    forReport: Boolean,
  },
  watch: {
    async selectedCabinets(): Promise<void> {
      await this.updateRoute();
    },
    async selectedStatuses(): Promise<void> {
      await this.updateRoute();
    },
    async selectedDeliveryType(): Promise<void> {
      await this.updateRoute();
    },
    async dates(): Promise<void> {
      await this.updateRoute();
    },
  },
  created(): void {
    let cabinets: string[] = [];
    let statuses: string[] = [];
    let deliveryType: string | null = null;

    const routeCabinets = this.$route.query.cabinets;
    const routeStatuses = this.$route.query.statuses;
    const routeDeliveryType = this.$route.query.delivery_type;
    const routeSince = this.$route.query.since as string | undefined;
    const routeTo = this.$route.query.to as string | undefined;

    if (routeCabinets) {
      cabinets = typeof routeCabinets === 'string' ? [routeCabinets] : routeCabinets as string[];
    }
    if (routeStatuses) {
      statuses = typeof routeStatuses === 'string' ? [routeStatuses] : routeStatuses as string[];
    }
    if (routeDeliveryType) {
      deliveryType = ['fbs', 'fbo'].includes(routeDeliveryType.toString()) ? routeDeliveryType.toString() : null;
    }

    if (routeSince && routeTo) {
      this.dates = [
        DateTime.fromISO(routeSince),
        DateTime.fromISO(routeTo),
      ];
    }

    this.selectedCabinets = cabinets.map((cabinet) => +cabinet);
    this.selectedStatuses = statuses;
    this.selectedDeliveryType = deliveryType;

    this.$nextTick(() => {
      this.created = true;
    });
  },
  methods: {
    async updateRoute(): Promise<void> {
      if (!this.created) return;

      const query: Record<string, string | string[] | undefined> = {
        cabinets: this.selectedCabinets.map((c) => c.toString()),
        statuses: this.selectedStatuses,
        delivery_type: this.selectedDeliveryType ?? undefined,
      };

      if (this.dates.length === 2) {
        query.since = this.dates[0].toISODate();
        query.to = this.dates[1]?.toISODate();
      } else {
        query.since = undefined;
        query.to = undefined;
      }

      query.page = '1';

      await this.$router.push({
        path: this.$route.fullPath,
        query,
      }).catch(() => 1);
    },
  },
});
