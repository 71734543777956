























































































import { DateTime } from 'luxon';
import Vue from 'vue';

import BDeliveryStatus from '@/components/delivery-status.vue';
import BOrdersTableHeader from '@/components/pages/orders/orders-table-header.vue';
import BNameCell from '@/components/products-table/name-cell.vue';
import BTableCell from '@/components/table/table-cell.vue';
import BToolbar from '@/components/toolbar.vue';
import { Cabinet } from '@/types/api/cabinets';
import { Order } from '@/types/api/orders';
import { HttpClient } from '@/utils/http-client';
import { downloadByLink } from '@/utils/utils';

export default Vue.extend({
  name: 'orders-index',
  components: {
    BToolbar,
    BOrdersTableHeader,
    BDeliveryStatus,
    BNameCell,
    BTableCell,
  },
  data() {
    return {
      activeTab: 0,
      orders: [] as Order[],
      pages: 1,
      perPage: 10,
      total: 0,
      filter: '',
      searchLoading: false,
      makingOrdersSpreadsheet: false,
    };
  },
  watch: {
    async $route() {
      await this.fetchOrders();
    },
    async activeTab() {
      if (this.activeTab === 1) {
        await this.$router.push({ name: 'orders.grouped' });
      } else if (this.activeTab === 2) {
        await this.$router.push({ name: 'orders.calendar' });
      }
    },
  },
  async created(): Promise<void> {
    await this.fetchOrders();
  },
  computed: {
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    cabinets(): Cabinet[] {
      return this.$store.state.cabinets;
    },
    cabinetName() {
      return (cabinetId: number) => {
        if (!cabinetId) return 'N/A';
        return this.cabinets.find((o) => o.id === cabinetId)?.name ?? 'N/A';
      };
    },
    date() {
      return (date: string | null) => {
        if (!date) return null;
        return DateTime.fromISO(date).toFormat('dd.LL.yyyy');
      };
    },
  },
  methods: {
    getSearchParams(): URLSearchParams {
      const page = this.$route.query.page ? +this.$route.query.page : 1;

      let cabinets: string[] = [];
      let statuses: string[] = [];
      let deliveryType: string | null = null;

      const routeCabinets = this.$route.query.cabinets;
      const routeStatuses = this.$route.query.statuses;
      const routeDeliveryType = this.$route.query.delivery_type;
      const routeSince = this.$route.query.since as string | undefined;
      const routeTo = this.$route.query.to as string | undefined;
      const search = this.$route.query.search as string | undefined;

      if (routeCabinets) {
        cabinets = typeof routeCabinets === 'string' ? [routeCabinets] : routeCabinets as string[];
      }
      if (routeStatuses) {
        statuses = typeof routeStatuses === 'string' ? [routeStatuses] : routeStatuses as string[];
      }
      if (routeStatuses) {
        statuses = typeof routeStatuses === 'string' ? [routeStatuses] : routeStatuses as string[];
      }
      if (routeDeliveryType) {
        deliveryType = ['fbs', 'fbo'].includes(routeDeliveryType.toString()) ? routeDeliveryType.toString() : null;
      }

      const sp = new URLSearchParams();
      sp.set('page', page.toString());
      sp.set('count', this.perPage.toString());

      cabinets.forEach((id) => {
        sp.append('cabinet[]', id);
      });

      statuses.forEach((id) => {
        sp.append('status[]', id);
      });

      if (deliveryType) {
        sp.set('deliveryType', deliveryType);
      }

      if (routeSince) {
        sp.set('since', DateTime.fromISO(routeSince).toLocal().toISO());
      }

      if (routeTo) {
        sp.set('to', DateTime.fromISO(routeTo).toLocal().plus({ days: 1 }).toISO());
      }

      if (search) {
        sp.set('search', search);
      }

      return sp;
    },

    async fetchOrders(): Promise<void> {
      this.searchLoading = true;

      const { items, total } = await HttpClient.getOrders({
        searchParams: this.getSearchParams(),
      });
      this.orders = items;
      this.pages = Math.ceil(total / this.perPage);
      this.total = total;
      this.searchLoading = false;
    },

    async mark(id: number): Promise<void> {
      const index = this.orders.findIndex((o) => o.id === id);

      if (index !== -1) {
        await this.$nextTick();
        this.orders[index].marked = !this.orders[index].marked;

        const response = await HttpClient.markOrder(id);
        this.orders[index].marked = response.marked;
      }
    },

    async downloadOrdersSpreadsheet(): Promise<void> {
      this.makingOrdersSpreadsheet = true;

      try {
        const response = await HttpClient.makeOrdersSpreadsheet({
          searchParams: this.getSearchParams(),
        });
        downloadByLink(response.path);
      } finally {
        this.makingOrdersSpreadsheet = false;
      }
    },
  },
});
