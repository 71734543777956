























import Vue from 'vue';

export default Vue.extend({
  name: 'b-header',
  methods: {
    toggleAside() {
      this.$store.commit('SET_ASIDE_ACTIVE', !this.$store.state.asideActive);
    },
  },
});
