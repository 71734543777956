






































import Vue, { PropType } from 'vue';

import { Product } from '@/types/api/products/product';
import { StockConnection } from '@/types/api/products/stock-connection';
import { DropdownOption } from '@/types/types';
import { HttpClient } from '@/utils/http-client';

export default Vue.extend({
  name: 'b-products-sets',
  props: {
    connectedProducts: {
      required: true,
      type: Array as PropType<StockConnection[]>,
    },
  },
  data() {
    return {
      products: [] as Product[],
      productsSearchLoading: false,
    };
  },
  async created(): Promise<void> {
    await this.fetchConnectedProducts();
  },
  computed: {
    syncedConnectedProducts: {
      get(): StockConnection[] {
        return this.connectedProducts;
      },
      set(value: StockConnection[]): void {
        this.$emit('update:connectedProducts', value);
      },
    },
    connectedProductIds(): number[] {
      return this.syncedConnectedProducts.map((product) => product.id);
    },
    productsDropdownOptions() {
      return (id: number): DropdownOption[] => {
        const options: DropdownOption[] = this.products
          .filter((p) => !this.connectedProductIds.includes(p.id))
          .map(
            (product) => ({
              key: product.id,
              value: product.id,
              text: product.article,
            }),
          );

        const product = this.syncedConnectedProducts.find((p) => p.id === id);
        if (product) {
          options.push({
            key: product.id,
            value: product.id,
            text: product.article,
          });
        }

        return options;
      };
    },
  },
  methods: {
    async fetchConnectedProducts(event?: KeyboardEvent) {
      const target = event?.target as HTMLInputElement | undefined;

      this.productsSearchLoading = true;
      const { items } = await HttpClient.getProducts({
        searchParams: {
          count: 30,
          search: target?.value || '',
        },
      });
      this.products = items;
      this.productsSearchLoading = false;
    },
    selectProduct(indexInArray: number, id: number) {
      const product = this.products.find((p) => p.id === id);
      if (product) {
        this.syncedConnectedProducts[indexInArray].id = id;
        this.syncedConnectedProducts[indexInArray].article = product.article;
      }
    },
    addRow(): void {
      this.syncedConnectedProducts.push({
        id: 0,
        article: '',
        count: 1,
      });
    },
    removeRow(index: number): void {
      this.syncedConnectedProducts.splice(index, 1);
    },
  },
});
