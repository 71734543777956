import { HTTPError } from 'ky';

import { ServerError } from '@/types/api/server-error';

export function pagination(c: number, m: number): number[] {
  const current: number = c;
  const last: number = m;
  const delta = 1;
  const left: number = current - delta;
  const right: number = current + delta + 1;
  const range: number[] = [];
  const rangeWithDots: number[] = [];
  let l: number | null = null;

  for (let i = 1; i <= last; i += 1) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  range.forEach((i) => {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push(0);
      }
    }
    rangeWithDots.push(i);
    l = i;
  });

  return rangeWithDots;
}

export async function getServerErrorMessage(error: unknown): Promise<string[]> {
  let message: string[] = [];
  if (error instanceof HTTPError) {
    const errorBody: ServerError | undefined = await error.response.json();
    if (!errorBody?.message) return message;
    if (typeof errorBody.message === 'string') {
      message.push(errorBody.message);
    } else if (Array.isArray(errorBody.message)) {
      message = [...errorBody.message];
    }
  } else {
    throw error;
  }

  return message;
}

export function downloadByLink(link: string): void {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = link;
  a.download = '';
  a.click();
}
