









import Vue, { PropType } from 'vue';

import BSelect, { SelectOption } from '@/components/select.vue';

export default Vue.extend({
  name: 'b-orders-delivery-types-dropdown',
  components: { BSelect },
  props: {
    value: {
      required: false,
      type: String as PropType<string | null>,
      default: null,
    },
  },
  computed: {
    options(): SelectOption[] {
      return [{
        id: null,
        title: 'Все',
      }, {
        id: 'fbs',
        title: 'FBS',
      }, {
        id: 'fbo',
        title: 'FBO',
      }];
    },
  },
});
