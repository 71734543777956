











































































import Vue from 'vue';

import BToolbar from '@/components/toolbar.vue';
import { Cabinet } from '@/types/api/cabinets';
import { HttpClient } from '@/utils/http-client';

export default Vue.extend({
  name: 'cabinets-index',
  components: { BToolbar },
  data() {
    return {
      deleteDialogOpened: false as boolean,
      deleteCabinetId: null as number | null,
      deleting: false as boolean,
      cabinets: [] as Cabinet[],
    };
  },
  watch: {
    async $route() {
      await this.fetchCabinets();
    },
  },
  async created(): Promise<void> {
    await this.fetchCabinets();
  },
  computed: {
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    deleteCabinetName(): string {
      const cabinet = this.cabinets.find((c) => c.id === this.deleteCabinetId);
      return cabinet?.name || cabinet?.id.toString() || 'Unknown';
    },
  },
  methods: {
    async fetchCabinets() {
      this.cabinets = await HttpClient.getCabinets();
    },

    openDeleteDialog(id: number): void {
      this.deleteCabinetId = id;
      this.deleteDialogOpened = true;
    },

    closeDeleteDialog(): void {
      this.deleteCabinetId = null;
      this.deleteDialogOpened = false;
      this.deleting = false;
    },

    async deleteEpisode(): Promise<void> {
      if (!this.deleteCabinetId) return;
      this.deleting = true;

      await HttpClient.deleteCabinetById(this.deleteCabinetId);
      await this.fetchCabinets();
      this.closeDeleteDialog();
    },
  },
});
