













































import Vue from 'vue';

export default Vue.extend({
  name: 'episodes-create',
  data() {
    return {
      errors: [] as string[],
      email: '' as string,
      password: '' as string,
    };
  },
  methods: {
    clearErrors(): void {
      this.errors = [];
    },

    async requestSignIn(): Promise<void> {
      this.clearErrors();

      const body = {
        email: this.email,
        password: this.password,
      };

      try {
        await this.$store.dispatch('signIn', body);
      } catch {
        this.errors = ['Неверный логин или пароль'];
      }
    },
  },
});
