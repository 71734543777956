






















































































































































import Vue, { PropType } from 'vue';

import { Cabinet } from '@/types/api/cabinets';
import {
  Product,
  ProductParserLink,
} from '@/types/api/products/product';
import { DropdownOption } from '@/types/types';

export default Vue.extend({
  name: 'b-products-parser',
  props: {
    product: {
      required: true,
      type: Object as PropType<Product>,
    },
  },
  computed: {
    cabinets(): Cabinet[] {
      return this.$store.state.cabinets;
    },
    cabinetName() {
      return (id: number): string => this.cabinets.find((c) => c.id === id)?.name ?? 'Unknown';
    },
    cabinetsDropdownOptions(): DropdownOption[] {
      return this.cabinets.map(
        (cabinet) => ({
          key: cabinet.id,
          value: cabinet.id,
          text: cabinet.name,
        }),
      );
    },
    syncedProduct: {
      get(): Product {
        return this.product;
      },
      set(value: Product): void {
        this.$emit('update:product', value);
      },
    },
    selectedCabinetIds: {
      get(): number[] {
        return this.syncedProduct.parser.variants
          .filter((v) => v.enabled)
          .map((v) => v.cabinetId);
      },
      set(value: number[]): void {
        value.forEach((cabinetId) => {
          const index = this.syncedProduct.parser.variants.findIndex((v) => v.cabinetId === cabinetId);
          if (index !== -1) {
            this.syncedProduct.parser.variants[index].enabled = true;
          } else {
            this.syncedProduct.parser.variants.push({
              cabinetId,
              enabled: true,
              adjustment: false,
              adjustmentValue: 0,
              adjustmentMinPrice: 0,
              adjustmentMaxPrice: 0,
              currentPrice: null,
              lastPrice: null,
              lastPriceLink: null,
            });
          }
        });

        this.syncedProduct.parser.variants.forEach((variant, i) => {
          if (!value.includes(variant.cabinetId)) {
            this.syncedProduct.parser.variants[i].enabled = false;
          }
        });
      },
    },
  },
  methods: {
    addLink(type: ProductParserLink['type']) {
      this.syncedProduct.parser.links.push({
        id: Date.now(),
        type,
        link: '',
      });
    },
    removeLink(index: number) {
      this.syncedProduct.parser.links.splice(index, 1);
    },
  },
});
