import Cookie from 'cookie-universal';
import Vue from 'vue';
import Vuex from 'vuex';

import { SignInRequestBody } from '@/types/api/accounts/sign-in.body';
import { Cabinet } from '@/types/api/cabinets';
import { HttpClient } from '@/utils/http-client';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedIn: true as boolean,
    isMobile: false as boolean,
    asideActive: false as boolean,
    cabinets: [] as Cabinet[],
  },
  mutations: {
    SET_LOGGED_IN(state, payload: boolean) {
      state.loggedIn = payload;
    },
    SET_IS_MOBILE(state, payload: boolean) {
      state.isMobile = payload;
    },
    SET_ASIDE_ACTIVE(state, payload: boolean) {
      state.asideActive = payload;
    },
    SET_CABINETS(state, payload: Cabinet[]) {
      state.cabinets = payload;
    },
  },
  actions: {
    async initialize({ commit, dispatch }) {
      const cookies = Cookie();
      commit('SET_LOGGED_IN', !!cookies.get('access_token'));

      commit('SET_IS_MOBILE', window.outerWidth <= 767);
      window.addEventListener('resize', () => {
        commit('SET_IS_MOBILE', window.outerWidth <= 767);
      });

      await dispatch('fetchCabinets');
    },
    async signIn({ commit }, payload: SignInRequestBody) {
      const responseBody = await HttpClient.signIn(payload);
      const cookies = Cookie();
      cookies.set('access_token', responseBody.value, {
        maxAge: 86_400 * 365 * 10,
        sameSite: 'lax',
        path: '/',
      });
      commit('SET_LOGGED_IN', true);
    },
    async logout({ commit }): Promise<void> {
      const cookies = Cookie();
      cookies.remove('access_token');
      commit('SET_LOGGED_IN', false);
    },
    async fetchCabinets({ commit }): Promise<void> {
      const cabinets = await HttpClient.getCabinets();
      commit('SET_CABINETS', cabinets);
    },
  },
});
