










































































import Vue from 'vue';

import BBrandCell from '@/components/products-table/brand-cell.vue';
import BNameCell from '@/components/products-table/name-cell.vue';
import BPricesCell from '@/components/products-table/prices-cell.vue';
import BStocksCell from '@/components/products-table/stocks-cell.vue';
import BSortingLink from '@/components/sorting-link.vue';
import BToolbar from '@/components/toolbar.vue';
import { Cabinet } from '@/types/api/cabinets';
import { Product } from '@/types/api/products/product';
import eventBus from '@/utils/event-bus';
import { HttpClient } from '@/utils/http-client';

export default Vue.extend({
  name: 'products-index',
  components: {
    BBrandCell,
    BNameCell,
    BPricesCell,
    BStocksCell,
    BSortingLink,
    BToolbar,
  },
  data() {
    return {
      products: [] as Product[],
      cabinets: [] as Cabinet[],
      pages: 1,
      searchLoading: false,
    };
  },
  watch: {
    async $route() {
      await this.fetchProducts();
    },
  },
  async created(): Promise<void> {
    this.cabinets = await HttpClient.getCabinets();
    await this.fetchProducts();
    eventBus.$on('server:synced', async () => {
      await this.fetchProducts();
    });
  },
  beforeDestroy(): void {
    eventBus.$off('server:synced');
  },
  computed: {
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    async fetchProducts() {
      this.searchLoading = true;
      const count = 10;
      const page = this.$route.query.page ? +this.$route.query.page : 1;
      const search = this.$route.query.search ? this.$route.query.search as string : '';
      const orderColumn = this.$route.query.column ? this.$route.query.column as string : 'article';
      const orderDirection = this.$route.query.direction ? this.$route.query.direction as string : 'asc';
      const { items, total } = await HttpClient.getProducts({
        searchParams: {
          page,
          count,
          search,
          orderColumn,
          orderDirection,
        },
      });
      this.products = items;
      this.pages = Math.ceil(total / count);
      this.searchLoading = false;
    },
  },
});
