





































































import Vue from 'vue';

import { Settings } from '@/types/api/settings/settings';
import { HttpClient } from '@/utils/http-client';
import {
  downloadByLink,
  getServerErrorMessage,
} from '@/utils/utils';

export default Vue.extend({
  name: 'settings-index',
  data() {
    return {
      settings: {
        ozonClientId: '',
        ozonApiKey: '',
        wildberriesApiKey: '',
        wildberriesWebSupplierId: '',
        wildberriesWebToken: '',
        ignoreSellers: '',
        fetchingEnabled: true,
        bynToRubRate: 0,
      } as Settings,
      errors: [] as string[],
      applying: false,
      makingMistakesSpreadsheet: false,
      deletingOutdatedCards: false,
    };
  },
  async created(): Promise<void> {
    await this.fetchSettings();
  },
  methods: {
    async apply(): Promise<void> {
      this.errors = [];

      this.applying = true;
      const success = await this.patchSettings();
      if (success) {
        await this.fetchSettings();
      }
      this.applying = false;
    },

    async fetchSettings(): Promise<void> {
      const settings: Settings = await HttpClient.getSettings();
      if (!settings) return;

      this.settings = settings;
    },

    async patchSettings():Promise<boolean> {
      try {
        await HttpClient.patchSettings(this.settings);
      } catch (error) {
        this.errors = await getServerErrorMessage(error);
        return false;
      }

      return true;
    },
    async downloadMistakesSpreadsheet(): Promise<void> {
      this.makingMistakesSpreadsheet = true;

      try {
        const response = await HttpClient.makeMistakesSpreadsheet();
        downloadByLink(response.path);
      } finally {
        this.makingMistakesSpreadsheet = false;
      }
    },
    async deleteOutdatedCards(): Promise<void> {
      this.deletingOutdatedCards = true;

      try {
        await HttpClient.deleteOutdatedCards();
      } finally {
        this.deletingOutdatedCards = false;
      }
    },
  },
});
