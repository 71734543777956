







































































































import Vue from 'vue';

import { PriceCompare } from '@/types/api/price_compares';
import { HttpClient } from '@/utils/http-client';
import { getServerErrorMessage } from '@/utils/utils';

export default Vue.extend({
  name: 'price-compares-edit',
  data() {
    return {
      priceCompare: {
        article: '',
        cabinetId: 0,
        enabled: true,
        adjustment: false,
        adjustmentValue: 0,
        adjustmentMinPrice: 0,
        adjustmentMaxPrice: 0,
        cabinet: {
          name: '',
        },
      } as PriceCompare,
      errors: [] as string[],
      applying: false,
      saving: false,
    };
  },
  async created(): Promise<void> {
    this.priceCompare = await HttpClient.getPriceCompareById(this.$route.params.id);
  },
  methods: {
    async save(redirect = false): Promise<void> {
      this.errors = [];

      if (redirect) {
        this.saving = true;
        const success = await this.patchPriceCompare();
        if (success) {
          await this.$router.push({ name: 'price_compares.index' });
        }
      } else {
        this.applying = true;
        await this.patchPriceCompare();
      }

      this.saving = false;
      this.applying = false;
    },

    async patchPriceCompare(): Promise<boolean> {
      try {
        await HttpClient.patchPriceCompare(this.priceCompare);
      } catch (error) {
        this.errors = await getServerErrorMessage(error);
        return false;
      }

      return true;
    },
  },
});
