

















import Vue from 'vue';

export default Vue.extend({
  name: 'b-aside-nav',
  data() {
    return {
      items: [{
        to: '/products',
        title: 'Товары',
      }, {
        to: '/orders',
        title: 'Заказы',
      }, {
        to: '/price_compares',
        title: 'Регулировка цен',
      }, {
        to: '/imports',
        title: 'Импорт',
      }, {
        to: '/cabinets',
        title: 'Кабинеты',
      }, {
        to: '/settings',
        title: 'Настройки',
      }, {
        to: '/logs',
        title: 'Лог',
      }, {
        to: '/tasks',
        title: 'Диспетчер задач',
      }, {
        to: '/copy',
        title: 'Копирование карточек',
      }],
    };
  },
  methods: {
    closeAside() {
      this.$store.commit('SET_ASIDE_ACTIVE', false);
    },
  },
});
