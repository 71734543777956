










import AirDatepicker from 'air-datepicker';
import { DateTime } from 'luxon';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'b-date',
  props: {
    value: {
      required: false,
      type: Array as PropType<DateTime[]>,
      default: () => [],
    },
  },
  data() {
    return {
      airDatepicker: null as AirDatepicker | null,
      range: [] as DateTime[],
      datesSeparator: ' - ',
      dateFormat: 'dd.MM.yyyy',
    };
  },
  created() {
    this.range = this.value;
  },
  mounted(): void {
    if (this.input) {
      this.airDatepicker = new AirDatepicker(this.input, {
        range: true,
        multipleDatesSeparator: this.datesSeparator,
        dateFormat: this.dateFormat,
        onSelect: (event) => {
          if (Array.isArray(event.date) && (event.date.length === 2 || event.date.length === 0)) {
            this.range = event.date.map((date) => DateTime.fromJSDate(date));
            this.$emit('input', this.range);
          }
        },
      });
      this.airDatepicker.selectDate(this.range.map((date) => date.toJSDate()));
      this.input.addEventListener('keydown', this.onKeydown);
    }
  },
  beforeDestroy(): void {
    this.airDatepicker?.destroy();
    this.input?.removeEventListener('keydown', this.onKeydown);
  },
  computed: {
    input(): HTMLInputElement | null {
      return this.$refs.input as HTMLInputElement | null;
    },
  },
  methods: {
    onKeydown(event: KeyboardEvent): void {
      if (this.input && event.code === 'Enter') {
        let isValid = true;

        if (!this.input.value.trim()) {
          this.clear();
        } else {
          const dates = this.input.value
            .split(' - ')
            .map((date) => {
              const dateTime = DateTime.fromFormat(date, this.dateFormat);
              if (!dateTime.isValid) {
                isValid = false;
              }
              return dateTime.toJSDate();
            });

          if (isValid) {
            this.airDatepicker?.selectDate(dates);
          }
        }
      }
    },
    clear(): void {
      this.airDatepicker?.clear();
    },
  },
});
