



















import Vue from 'vue';

import {
  Cabinet,
  CabinetType,
} from '@/types/api/cabinets';
import {
  Product,
} from '@/types/api/products/product';

type Column = { value: number | null, watching: boolean, adjustment: boolean };
type CompactList = Partial<Record<CabinetType, (Column | null)[]>>;

export default Vue.extend({
  name: 'b-prices-cell',
  props: {
    product: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    cabinets(): Cabinet[] {
      const cabinets: Cabinet[] = [...this.$store.state.cabinets];
      return cabinets
        .sort((a, b) => {
          const nameA = a.name?.toLowerCase() || '';
          const nameB = b.name?.toLowerCase() || '';

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        })
        .sort((a, b) => {
          const weights: Record<CabinetType, number> = {
            [CabinetType.OZON]: 1,
            [CabinetType.WB]: 2,
          };

          return weights[a.type] - weights[b.type];
        });
    },
    columnMods() {
      return (column: Column | null) => {
        const modifiers: string[] = [];

        if (column?.watching) { modifiers.push('prices-cell__col_style_watching'); }
        if (column?.adjustment) { modifiers.push('prices-cell__col_style_adjustment'); }

        return modifiers;
      };
    },
    price() {
      return (column: Column | null) => {
        if (column === null) return '—';
        if (column.value === null) return '?';
        const format = new Intl.NumberFormat('de-DE').format(column.value);
        return `${format}р.`;
      };
    },
    rows(): CompactList {
      const list: CompactList = {};

      const product = this.product as Product;

      this.cabinets.forEach((cabinet) => {
        if (!Array.isArray(list[cabinet.type])) {
          list[cabinet.type] = [];
        }

        let column: Column | null = null;
        const productCabinet = product.variants.find((v) => v.cabinetId === cabinet.id);

        if (productCabinet) {
          column = {
            value: productCabinet.price,
            watching: productCabinet.priceWatching,
            adjustment: productCabinet.priceAdjustment,
          };
        }

        list[cabinet.type]?.push(column);
      });

      return list;
    },
  },
  methods: {},
});
