import { render, staticRenderFns } from "./orders.index.vue?vue&type=template&id=34db15cb&scoped=true&"
import script from "./orders.index.vue?vue&type=script&lang=ts&"
export * from "./orders.index.vue?vue&type=script&lang=ts&"
import style0 from "./orders.index.vue?vue&type=style&index=0&id=34db15cb&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34db15cb",
  null
  
)

export default component.exports