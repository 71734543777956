






































































































import Vue from 'vue';

import { Cabinet } from '@/types/api/cabinets';
import { HttpClient } from '@/utils/http-client';
import {
  getServerErrorMessage,
} from '@/utils/utils';

export default Vue.extend({
  name: 'cabinets-create',
  data() {
    return {
      cabinet: {
        enabled: true,
        type: 'OZON',
        name: '',
        vat: 0,
        credentials: {},
      } as Cabinet,
      typeDropdownOptions: [
        { key: 'OZON', value: 'OZON', text: 'OZON' },
        { key: 'WB', value: 'WB', text: 'WB' },
      ],
      errors: [] as string[],
      saving: false,
      sharedStock: 0,
    };
  },
  methods: {
    async save(): Promise<void> {
      this.errors = [];

      this.saving = true;
      const success = await this.storeCabinet();
      if (success) {
        await this.$router.push({ name: 'cabinets.index' });
      }

      this.saving = false;
    },

    async storeCabinet():Promise<boolean> {
      try {
        await HttpClient.storeCabinet(this.cabinet);
      } catch (error) {
        this.errors = await getServerErrorMessage(error);
        return false;
      }

      return true;
    },
  },
});
