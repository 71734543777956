






import Vue from 'vue';

import { Product } from '@/types/api/products/product';

export default Vue.extend({
  name: 'b-brand-cell',
  props: {
    product: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    typedProduct(): Product {
      return this.product;
    },
    brand(): string {
      return this.typedProduct.brand || '—';
    },
  },
  methods: {},
});
