import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Empty from '../views/empty.vue';
import OrdersCalendar from '../views/orders/orders.calendar.vue';

import CabinetsCreate from '@/views/cabinets/cabinets.create.vue';
import CabinetsEdit from '@/views/cabinets/cabinets.edit.vue';
import CabinetsIndex from '@/views/cabinets/cabinets.index.vue';
import ImportsIndex from '@/views/imports/index.imports.vue';
import LogsIndex from '@/views/logs/logs.index.vue';
import OrdersGrouped from '@/views/orders/orders.grouped.vue';
import OrdersIndex from '@/views/orders/orders.index.vue';
import OrdersReport from '@/views/orders/orders.report.vue';
import PriceComparesCreate from '@/views/price-compares/price-compares.create.vue';
import PriceComparesEdit from '@/views/price-compares/price-compares.edit.vue';
import PriceComparesIndex from '@/views/price-compares/price-compares.index.vue';
import ProductsCopy from '@/views/products/products.copy.vue';
import ProductsEdit from '@/views/products/products.edit.vue';
import ProductsIndex from '@/views/products/products.index.vue';
import SettingsIndex from '@/views/settings/settings.index.vue';
import TasksIndex from '@/views/tasks/tasks.index.vue';
import TasksShow from '@/views/tasks/tasks.show.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/products',
    name: 'home',
  },
  {
    path: '/settings',
    component: Empty,
    meta: {
      breadcrumb: 'Настройки',
    },
    children: [
      {
        path: '',
        name: 'settings.index',
        component: SettingsIndex,
      },
    ],
  },
  {
    path: '/cabinets',
    component: Empty,
    meta: {
      breadcrumb: 'Кабинеты',
    },
    children: [
      {
        path: '',
        name: 'cabinets.index',
        component: CabinetsIndex,
      },
      {
        path: 'create',
        name: 'cabinets.create',
        component: CabinetsCreate,
        meta: {
          breadcrumb: 'Создать',
        },
      },
      {
        path: ':id',
        name: 'cabinets.edit',
        component: CabinetsEdit,
      },
    ],
  },
  {
    path: '/products',
    component: Empty,
    meta: {
      breadcrumb: 'Товары',
    },
    children: [
      {
        path: '',
        name: 'products.index',
        component: ProductsIndex,
      },
      {
        path: ':article',
        name: 'products.edit',
        component: ProductsEdit,
        meta: {
          breadcrumb: 'Редактировать',
        },
      },
    ],
  },
  {
    path: '/price_compares',
    component: Empty,
    meta: {
      breadcrumb: 'Регулировка цен',
    },
    children: [
      {
        path: '',
        name: 'price_compares.index',
        component: PriceComparesIndex,
      },
      {
        path: 'create',
        name: 'price_compares.create',
        component: PriceComparesCreate,
      },
      {
        path: ':id',
        name: 'price_compares.edit',
        component: PriceComparesEdit,
        meta: {
          breadcrumb: 'Редактировать',
        },
      },
    ],
  },
  {
    path: '/logs',
    component: Empty,
    meta: {
      breadcrumb: 'Лог',
    },
    children: [
      {
        path: '',
        name: 'logs.index',
        component: LogsIndex,
      },
    ],
  },
  {
    path: '/orders',
    component: Empty,
    meta: {
      breadcrumb: 'Заказы',
    },
    children: [
      {
        path: '',
        name: 'orders.index',
        component: OrdersIndex,
        meta: {
          breadcrumb: 'Список',
        },
      },
      {
        path: 'grouped',
        name: 'orders.grouped',
        component: OrdersGrouped,
        meta: {
          breadcrumb: 'Группировка',
        },
      },
      {
        path: 'calendar',
        name: 'orders.calendar',
        component: OrdersCalendar,
        meta: {
          breadcrumb: 'Календарь',
        },
        children: [
          {
            path: ':date',
            name: 'orders.report',
            component: OrdersReport,
          },
        ],
      },
    ],
  },
  {
    path: '/imports',
    component: Empty,
    meta: {
      breadcrumb: 'Импорт',
    },
    children: [
      {
        path: '',
        name: 'imports.index',
        component: ImportsIndex,
      },
    ],
  },
  {
    path: '/tasks',
    component: Empty,
    meta: {
      breadcrumb: 'Диспетчер задач',
    },
    children: [
      {
        path: '',
        name: 'tasks.index',
        component: TasksIndex,
      },
      {
        path: ':id',
        name: 'tasks.show',
        component: TasksShow,
      },
    ],
  },
  {
    path: '/copy',
    component: Empty,
    meta: {
      breadcrumb: 'Копирование карточек',
    },
    children: [
      {
        path: '',
        name: 'copy.index',
        component: ProductsCopy,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
