





































import Vue from 'vue';

import BAsideNav from '@/components/aside-nav.vue';
import BHeader from '@/components/header.vue';
import eventBus from '@/utils/event-bus';
import { HttpClient } from '@/utils/http-client';
import SignIn from '@/views/auth/sign-in.vue';

export default Vue.extend({
  name: 'App',
  components: {
    BAsideNav,
    BHeader,
    SignIn,
  },
  data() {
    return {
      syncing: false,
      syncingOrders: false,
      syncingPriceCompares: false,
    };
  },
  methods: {
    closeAside() {
      this.$store.commit('SET_ASIDE_ACTIVE', false);
    },

    async sync() {
      this.syncing = true;
      try {
        await HttpClient.sync();
        eventBus.$emit('server:synced');
      } finally {
        this.syncing = false;
      }
    },

    async syncOrders() {
      this.syncingOrders = true;
      try {
        await HttpClient.syncOrders();
        eventBus.$emit('server:synced');
      } finally {
        this.syncingOrders = false;
      }
    },

    async syncPriceCompares() {
      this.syncingPriceCompares = true;
      try {
        await HttpClient.syncPriceCompares();
        eventBus.$emit('server:synced');
      } finally {
        this.syncingPriceCompares = false;
      }
    },
  },
});
