















































import Vue from 'vue';

import { DropdownOption } from '@/types/types';
import { HttpClient } from '@/utils/http-client';
import { getServerErrorMessage } from '@/utils/utils';

export default Vue.extend({
  name: 'imports-index',
  data() {
    return {
      type: 'prices' as 'prices' | 'stocks' | 'stocks-v2' | 'supplier_articles',

      errors: [] as string[],
      applying: false,
    };
  },
  computed: {
    typeDropdownOptions(): DropdownOption[] {
      return [{
        key: 'supplier_articles',
        value: 'supplier_articles',
        text: 'Артикулы поставщика',
      }, {
        key: 'prices',
        value: 'prices',
        text: 'Цены',
      }, {
        key: 'stocks',
        value: 'stocks',
        text: 'Остатки',
      }];
    },
  },
  methods: {
    async apply(): Promise<void> {
      this.errors = [];

      this.applying = true;
      await this.import();
      this.applying = false;
    },

    async import():Promise<boolean> {
      const inputComponent: Vue | undefined = (this.$refs.input as Vue | undefined);
      const input: HTMLInputElement | undefined = inputComponent?.$refs?.input as HTMLInputElement | undefined;
      if (!input?.files?.[0]) return false;

      try {
        const formData = new FormData();
        formData.append('file', input?.files[0]);

        let taskId;

        switch (this.type) {
          case 'supplier_articles': {
            taskId = await HttpClient.importSupplierArticles({
              body: formData,
            });

            break;
          }
          case 'stocks': {
            taskId = await HttpClient.importStocks({
              body: formData,
            });

            break;
          }
          default: {
            taskId = await HttpClient.importPrices({
              body: formData,
            });
          }
        }

        await this.$router.push({ name: 'tasks.show', params: { id: taskId.toString() } });
      } catch (error) {
        this.errors = await getServerErrorMessage(error);
        return false;
      }

      return true;
    },
  },
});
