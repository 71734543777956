






import Vue from 'vue';

export default Vue.extend({
  name: 'b-table-cell',
  props: {
    head: {
      required: false,
      type: Boolean,
      default: false,
    },
    collapsing: {
      required: false,
      type: Boolean,
      default: false,
    },
    shrinkable: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tag(): string {
      return this.head ? 'th' : 'td';
    },
    modifiers(): string[] {
      const modifiers: string[] = [];
      if (this.head) modifiers.push('table__cell_style_head');
      if (this.collapsing) modifiers.push('table__cell_style_collapsing');
      if (this.shrinkable) modifiers.push('table__cell_style_shrinkable');
      return modifiers;
    },
  },
});
