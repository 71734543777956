import Cookie from 'cookie-universal';
import ky, { Options } from 'ky';

import { SignInRequestBody } from '@/types/api/accounts/sign-in.body';
import { Token } from '@/types/api/accounts/token';
import { Cabinet } from '@/types/api/cabinets';
import { Log } from '@/types/api/logs/log';
import { Order } from '@/types/api/orders';
import { GroupedOrder } from '@/types/api/orders/grouped';
import { Pagination } from '@/types/api/pagination';
import { PriceCompare } from '@/types/api/price_compares';
import { PriceCompareCreateBody } from '@/types/api/price_compares/store';
import { Product } from '@/types/api/products/product';
import { ProductBody } from '@/types/api/products/product.body';
import { Settings } from '@/types/api/settings/settings';
import { Task } from '@/types/api/tasks';

export class HttpClient {
  private static readonly baseURL = '/api/v1';

  private static readonly httpClient = ky.extend({
    prefixUrl: HttpClient.baseURL,
    timeout: false,
    headers: {
      lang: 'ru',
    },
    hooks: {
      beforeRequest: [
        ((request) => {
          const cookies = Cookie();
          const token = cookies.get('access_token');
          if (token) {
            request.headers.set('Authorization', `Bearer ${token}`);
          }
        }),
      ],
      afterResponse: [
        (request, options, response) => {
          if (response.status === 401) {
            const cookies = Cookie();

            if (cookies.get('access_token')) {
              cookies.remove('access_token');
              window.location.reload();
            }
          }

          return response;
        },
      ],
    },
  });

  public static signIn(body: SignInRequestBody): Promise<Token> {
    return this.httpClient.post('accounts/sign-in', { json: body }).json<Token>();
  }

  public static getProductByArticle(payload: { article: string }, options: Options = {}): Promise<Product> {
    return this.httpClient
      .get(`products/${encodeURIComponent(payload.article)}`, { ...options })
      .json<Product>();
  }

  public static async patchProduct(article: string, body: ProductBody, options: Options = {}): Promise<void> {
    await this.httpClient
      .patch(`products/${encodeURIComponent(article)}`, { json: body, ...options });
  }

  public static getProducts(options: Options = {}): Promise<Pagination<Product>> {
    return this.httpClient.get('products', { ...options }).json<Pagination<Product>>();
  }

  public static getSettings(options: Options = {}): Promise<Settings> {
    return this.httpClient.get('settings', { ...options }).json<Settings>();
  }

  public static async patchSettings(body: Settings, options: Options = {}): Promise<Settings> {
    return this.httpClient
      .patch('settings', { json: body, ...options })
      .json<Settings>();
  }

  public static async sync(options: Options = {}): Promise<void> {
    await this.httpClient.post('products/sync', { ...options });
  }

  public static getLogs(options: Options = {}): Promise<Pagination<Log>> {
    return this.httpClient.get('logs', { ...options }).json<Pagination<Log>>();
  }

  public static getCabinets(options: Options = {}): Promise<Cabinet[]> {
    return this.httpClient.get('settings/cabinets', { ...options }).json<Cabinet[]>();
  }

  public static getCabinetById(id: number | string, options: Options = {}): Promise<Cabinet> {
    return this.httpClient
      .get(`settings/cabinets/${id}`, { ...options })
      .json<Cabinet>();
  }

  public static async storeCabinet(body: Cabinet, options: Options = {}): Promise<Cabinet> {
    return this.httpClient
      .post('settings/cabinets', { json: body, ...options })
      .json<Cabinet>();
  }

  public static async patchCabinet(body: Cabinet, options: Options = {}): Promise<Cabinet> {
    return this.httpClient
      .patch(`settings/cabinets/${body.id}`, { json: body, ...options })
      .json<Cabinet>();
  }

  public static async deleteCabinetById(id: number | string, options: Options = {}): Promise<void> {
    await this.httpClient.delete(`settings/cabinets/${id}`, { ...options });
  }

  public static getPriceCompares(options: Options = {}): Promise<Pagination<PriceCompare>> {
    return this.httpClient.get('price-compares', { ...options }).json<Pagination<PriceCompare>>();
  }

  public static getPriceCompareById(id: number | string, options: Options = {}): Promise<PriceCompare> {
    return this.httpClient
      .get(`price-compares/${id}`, { ...options })
      .json<PriceCompare>();
  }

  public static async storePriceCompare(body: PriceCompareCreateBody, options: Options = {}): Promise<PriceCompare> {
    return this.httpClient
      .post('price-compares', { json: body, ...options })
      .json<PriceCompare>();
  }

  public static async patchPriceCompare(body: PriceCompare, options: Options = {}): Promise<PriceCompare> {
    return this.httpClient
      .patch(`price-compares/${body.id}`, { json: body, ...options })
      .json<PriceCompare>();
  }

  public static async deletePriceCompareById(id: number | string, options: Options = {}): Promise<void> {
    await this.httpClient.delete(`price-compares/${id}`, { ...options });
  }

  public static async syncPriceCompares(options: Options = {}): Promise<void> {
    await this.httpClient.post('price-compares/sync', { ...options });
  }

  public static async syncPriceCompare(id: number | string, options: Options = {}): Promise<void> {
    await this.httpClient.post(`price-compares/sync/${id}`, { ...options });
  }

  public static async makeParserExport(options: Options = {}): Promise<string> {
    return this.httpClient.post('price-compares/export', { ...options })
      .text();
  }

  public static getOrders(options: Options = {}): Promise<Pagination<Order>> {
    return this.httpClient.get('orders', { ...options }).json<Pagination<Order>>();
  }

  public static getOrdersForReport(options: Options = {}): Promise<Pagination<Order> & { totalCost: number }> {
    return this.httpClient.get('orders/for-report', { ...options }).json<Pagination<Order> & { totalCost: number }>();
  }

  public static getGroupedOrders(options: Options = {}): Promise<Pagination<GroupedOrder>> {
    return this.httpClient.get('orders/grouped', { ...options }).json<Pagination<GroupedOrder>>();
  }

  public static async syncOrders(options: Options = {}): Promise<void> {
    await this.httpClient.post('orders/sync', { ...options });
  }

  public static async importSupplierArticles(options: Options = {}): Promise<number> {
    return this.httpClient.post('products/import/supplier-articles', { ...options })
      .json<number>();
  }

  public static async importPrices(options: Options = {}): Promise<number> {
    return this.httpClient.post('products/import/prices', { ...options })
      .json<number>();
  }

  public static async importStocks(options: Options = {}): Promise<number> {
    return this.httpClient.post('products/import/stocks', { ...options })
      .json<number>();
  }

  public static getTasks(options: Options = {}): Promise<Pagination<Task>> {
    return this.httpClient.get('tasks', { ...options }).json<Pagination<Task>>();
  }

  public static getTaskById(id: number | string, options: Options = {}): Promise<Task> {
    return this.httpClient
      .get(`tasks/${id}`, { ...options })
      .json<Task>();
  }

  public static async markOrder(id: number | string, options: Options = {}): Promise<{marked: boolean}> {
    return this.httpClient.patch(`orders/${id}/mark`, { ...options })
      .json<{marked: boolean}>();
  }

  public static async copy(
    body: {
      variantId: number;
      targetCabinetId: number;
      update: boolean;
    },
    options: Options = {},
  ): Promise<void> {
    await this.httpClient.post('products/copy', {
      json: body,
      ...options,
    });
  }

  public static async makeOrdersReportsExport(date: string, options: Options = {}): Promise<{
    path: string,
    hasEmptyBarcodes: boolean,
  }> {
    return this.httpClient.post('orders/reports/export', {
      json: { date },
      ...options,
    }).json();
  }

  public static async makeOrdersReportsList(date: string, options: Options = {}): Promise<string> {
    return this.httpClient.post('orders/reports/list', {
      json: { date },
      ...options,
    }).text();
  }

  public static async makeMistakesSpreadsheet(options: Options = {}): Promise<{ path: string }> {
    return this.httpClient.post('spreadsheets/mistakes', {
      ...options,
    }).json();
  }

  public static async makeOrdersSpreadsheet(options: Options = {}): Promise<{ path: string }> {
    return this.httpClient.post('spreadsheets/orders', {
      ...options,
    }).json();
  }

  public static async setOrderShipmentAt(
    id: number | string,
    date: string | null,
    options: Options = {},
  ): Promise<Order> {
    return this.httpClient.post(`orders/${id}/shipment-at`, {
      json: { date },
      ...options,
    }).json();
  }

  public static async getOrdersMonthStats(
    year: number,
    month: number,
  ): Promise<{ totalOrders: number; totalCost: number; }> {
    return this.httpClient.get('orders/month-stats', {
      searchParams: { month, year },
    }).json();
  }

  public static async deleteOutdatedCards(): Promise<void> {
    await this.httpClient.post('products/delete-outdated');
  }
}
