









import Vue, { PropType } from 'vue';

import BMultipleSelect, { MultipleSelectOption } from '@/components/multiple-select.vue';
import { Cabinet } from '@/types/api/cabinets';

export default Vue.extend({
  name: 'b-orders-cabinets-dropdown',
  components: { BMultipleSelect },
  props: {
    value: {
      required: false,
      type: Array as PropType<Array<string | number>>,
      default: () => [],
    },
  },
  computed: {
    cabinets(): Cabinet[] {
      return this.$store.state.cabinets;
    },
    options(): MultipleSelectOption[] {
      const options: MultipleSelectOption[] = [];

      this.cabinets
        .forEach((cabinet) => {
          options.push({
            id: cabinet.id,
            title: cabinet.name,
          });
        });

      return options;
    },
  },
});
