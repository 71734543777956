


















































import Vue from 'vue';

import { pagination } from '@/utils/utils';

export default Vue.extend({
  name: 'b-pagination',
  props: {
    pages: {
      type: Number,
      required: false,
    },
  },
  computed: {
    page(): number {
      return this.$route.query.page ? +this.$route.query.page : 1;
    },
    pageQuery() {
      return (number: number): string => {
        const route = this.$router.resolve({
          path: this.$route.fullPath,
          query: { page: number.toString() },
        });
        return route.href;
      };
    },
    pagination(): number[] {
      return pagination(this.page, this.pages);
    },
  },
});
