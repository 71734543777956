









import Vue, { PropType } from 'vue';

import BMultipleSelect, { MultipleSelectOption } from '@/components/multiple-select.vue';

export default Vue.extend({
  name: 'b-orders-statuses-dropdown',
  components: { BMultipleSelect },
  props: {
    value: {
      required: false,
      type: Array as PropType<Array<string | number>>,
      default: () => [],
    },
  },
  computed: {
    options(): MultipleSelectOption[] {
      return [{
        id: 'new',
        title: 'Новый',
      }, {
        id: 'awaiting',
        title: 'Ожидает',
      }, {
        id: 'delivering',
        title: 'Отправлен',
      }, {
        id: 'delivered',
        title: 'Доставлен',
      }, {
        id: 'cancelled',
        title: 'Отменён',
      }, {
        id: 'refund',
        title: 'Возврат',
      }];
    },
  },
});
