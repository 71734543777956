























































































































import Vue from 'vue';

import { Cabinet } from '@/types/api/cabinets';
import { HttpClient } from '@/utils/http-client';
import {
  getServerErrorMessage,
} from '@/utils/utils';

export default Vue.extend({
  name: 'cabinets-edit',
  data() {
    return {
      cabinet: {
        id: 0,
        enabled: true,
        type: 'OZON',
        name: '',
        vat: 0,
        credentials: {},
      } as Cabinet,
      typeDropdownOptions: [
        { key: 'OZON', value: 'OZON', text: 'OZON' },
        { key: 'WB', value: 'WB', text: 'WB' },
      ],
      errors: [] as string[],
      applying: false,
      saving: false,
      sharedStock: 0,
    };
  },
  async created(): Promise<void> {
    await this.fetchCabinet();
  },
  methods: {
    async save(redirect = false): Promise<void> {
      this.errors = [];

      if (redirect) {
        this.saving = true;
        const success = await this.patchCabinet();
        if (success) {
          await this.$router.push({ name: 'cabinets.index' });
        }
      } else {
        this.applying = true;
        await this.patchCabinet();
      }

      this.saving = false;
      this.applying = false;
    },

    async fetchCabinet(): Promise<void> {
      const cabinet: Cabinet = await HttpClient.getCabinetById(this.$route.params.id);
      if (!cabinet) return;

      this.cabinet = cabinet;
    },

    async patchCabinet():Promise<boolean> {
      try {
        await HttpClient.patchCabinet(this.cabinet);
      } catch (error) {
        this.errors = await getServerErrorMessage(error);
        return false;
      }

      return true;
    },
  },
});
